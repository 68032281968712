import styled from "styled-components";

export const FooterContainer = styled.footer`
  width: 100%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    height: 40vh;
  }
`;

export const FooterWrapper = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: #f5f5f5;
    font-size: 1.25rem;
    letter-spacing: 2px;
    margin: 0;
    padding: 0;
  }
  @media screen and (max-width: 1000px) {
    width: 90%;
    height: 40vh;
    flex-direction: column;
    justify-content: space-evenly;
    p {
      font-family: "Roboto", sans-serif;
      font-size: 0.7rem;
      margin-top: 1rem;
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  a {
    margin: 0 1rem 0 0;
  }
  @media screen and (max-width: 1000px) {
    width: 70%;
  }
`;

export const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 65%;

  @media screen and (max-width: 1000px) {
    width: 100%;
    height: auto;
    align-items: center;
  }
`;

export const LinksContainer = styled.div`
  display: grid;
  margin-bottom: 2rem;
  a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 1.2rem;
    letter-spacing: 2px;
  }
  @media screen and (max-width: 1000px) {
    margin-bottom: 2rem;
  }
`;

export const IconFooter = styled.a`
  text-decoration: none;
  margin: 0 0 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border: 1px solid #f5f5f5;
  transition: 0.3s;

  &:hover,
  &:focus {
    box-shadow: inset calc(45px) 0 0 0 #f5f5f5;
    svg {
      color: #000;
    }
  }
  svg {
    font-size: 1.15rem;
    color: var(--white);
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) and (min-width: 500px) {
    width: 55px;
    height: 55px;
    margin: 0 0 0 0.5rem;
    svg {
      font-size: 1.7rem;
    }
  }
  @media screen and (max-width: 500px) {
    margin: 0 0 0 0.5rem;
    width: 55px;
    height: 55px;
    svg {
      font-size: 1.6rem;
      color: var(--white);
      cursor: pointer;
    }
  }
`;
