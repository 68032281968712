import styled from "styled-components";

export const GridContainer = styled.article`
  width: 90%;
  margin: 4rem auto 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  font-family: "Sarpanch", sans-serif;
  .item_container {
    position: relative;
    width: 15rem;
    height: 15rem;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      border: 2px solid white;
      -webkit-box-shadow: 0px 0px 30px 2px #87002b;
      box-shadow: 0px 0px 30px 2px #87002b;
    }
    svg {
      padding: 0.5rem;
      bottom: calc(25px / 2);
      right: calc(25px / 2);
      width: 50px;
      height: 50px;
      position: absolute;
      background: #1da1f2;
      border-radius: 50%;
      color: #fff;
    }
  }
  h2 {
    margin: 0.75rem auto 0.25rem;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    color: #f5f5f5;
  }
  p {
    font-family: "Roboto", sans-serif;
    margin: 0.25rem auto 0.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #f5f5f5;
  }
  @media screen and (max-width: 1500px) and (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    h2 {
      font-size: 16px;
      line-height: 23px;
    }
    p {
      font-family: "Roboto", sans-serif;
      font-size: 13px;
      line-height: 19px;
    }
  }
  @media screen and (max-width: 1000px) and (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }
`;
