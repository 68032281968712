import React from "react";
import { Container, DescContainer } from "./ExtincStyles";
// import { Container } from "../Hero/HeroStyles";
import background from "../../images/cityBackground.png";

const Extinct = () => {
  return (
    <>
      <Container name="extinct">
        <DescContainer
          style={{
            background: `url(${background})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h1>The Humans Have Gone Extinct...</h1>
          <p>
            ...in their place the Mechanix have risen from humanity's ashes.
          </p>
          <p>
            Within the Mechanix are 3 leading factions:
            <br /> Burn, Kabuto, and Masher.
          </p>
          <p>
            These 3 factions lead the Mechanix on a path of prosperity and progress,
            learning to repurpose what the humans left behind.
          </p>
          <p>
            The largest use of their repurposed technology is <br />
            the Mechs, used to compete in contests in order to resolve conflict
            with innovation and competition rather than brutal violence.
          </p>
          <p>Who will you choose?</p>
        </DescContainer>
      </Container>
    </>
  );
};

export default Extinct;
