import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  min-height: calc(100vh - 100px);
  padding: 2vw 5vw;
  /* background: #000; */
  color: #f5f5f5;
  a {
    text-decoration: none;
    color: #f5f5f5;
    word-wrap: break-word;
  }
`;
