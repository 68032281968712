import React from "react";
import { BurnoutContainer } from "./VisionStyles";

const Burnout = ({ img }) => {
  return (
    <BurnoutContainer shadow={true}>
      <img src={img} alt="burnout" />
    </BurnoutContainer>
  );
};

export default Burnout;
