import React from "react";
import { Link } from "react-router-dom";
import { Container } from "./404Styles";

const Page404 = () => {
  return (
    <Container>
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>
        The page you are looking for might have been removed, had its name
        changed or its temporarily unavailable.
      </p>
      <Link to="/">HOMEPAGE</Link>
    </Container>
  );
};

export default Page404;
