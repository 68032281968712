import React from 'react';

const Opensea = () => {
  return (
<svg width="" height="" viewBox="0 -17 60 90" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M3.3,28.51l.2-.3L15.2,9.89a.4.4,0,0,1,.7,0c2,4.39,3.65,9.84,2.86,13.23a20.84,20.84,0,0,1-2.31,5c-.13.25-.28.5-.43.74a.42.42,0,0,1-.34.18h-12A.4.4,0,0,1,3.3,28.51Z"/><path d="M55.48,31.91v2.9a.41.41,0,0,1-.25.38c-.91.39-4,1.82-5.3,3.61-3.29,4.58-5.8,11.13-11.43,11.13H15.05A15.08,15.08,0,0,1,0,34.83v-.27a.4.4,0,0,1,.4-.4H13.48a.44.44,0,0,1,.42.5,4.5,4.5,0,0,0,.47,2.51,4.61,4.61,0,0,0,4.12,2.56H25V34.67h-6.4a.41.41,0,0,1-.34-.64c.07-.11.15-.22.24-.34.6-.86,1.47-2.2,2.33-3.72a31.19,31.19,0,0,0,1.61-3.22c.09-.2.17-.41.24-.61.12-.35.25-.67.35-1s.16-.57.24-.84a13.54,13.54,0,0,0,.31-2.95c0-.41,0-.83-.06-1.23s-.07-.88-.13-1.32-.1-.77-.18-1.17c-.09-.59-.22-1.17-.37-1.76l0-.22c-.11-.4-.2-.79-.33-1.19-.37-1.26-.79-2.49-1.23-3.64-.16-.46-.35-.9-.53-1.34-.28-.66-.55-1.26-.81-1.83-.13-.26-.24-.49-.35-.73s-.25-.55-.38-.81-.2-.38-.28-.57l-.79-1.46a.26.26,0,0,1,.29-.37L23.3,5.05h0l.66.18.71.21.27.07V2.57A2.54,2.54,0,0,1,29.31.75a2.59,2.59,0,0,1,.74,1.82V6.93l.53.15.12.06.55.42c.18.15.38.33.62.51.48.39,1.05.88,1.67,1.45.17.14.33.29.48.44.8.75,1.7,1.63,2.56,2.6q.36.4.72.84c.24.29.5.59.72.88s.6.79.88,1.21l.4.6c.35.53.66,1.08,1,1.63.12.26.25.53.36.81a11,11,0,0,1,.75,2.23,2.29,2.29,0,0,1,.11.49v0a3.94,3.94,0,0,1,.09.7,7.21,7.21,0,0,1-.13,2.31,9.26,9.26,0,0,1-.27,1c-.11.32-.22.64-.36.95a12.66,12.66,0,0,1-1,1.89c-.12.22-.27.46-.42.68s-.33.46-.48.68-.42.56-.64.82a8.32,8.32,0,0,1-.62.79c-.31.36-.61.71-.92,1s-.38.44-.58.64-.41.42-.59.61-.57.55-.79.75l-.51.46a.38.38,0,0,1-.27.1H30.05v5.06h5a4.58,4.58,0,0,0,3-1.12,34.6,34.6,0,0,0,3.07-3,.35.35,0,0,1,.19-.12L55,31.53A.4.4,0,0,1,55.48,31.91Z"/></svg>
)

}

export default Opensea;

