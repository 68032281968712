import React from "react";
import fomoImage from "../../images/fomo.png";
import { NavContainer } from "./WhitelistStyle";

const WhitelistNav = () => {
  return (
    <NavContainer>
      {/* <img src={fomoImage} alt="Fomo" /> */}
    </NavContainer>
  );
};

export default WhitelistNav;
