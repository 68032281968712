import React from "react";
import { Container } from "../Privacy/PrivacyStyles";

const TermsOfService = () => {
  return (
    <Container>
      <h1>Terms Of Service</h1>
      <p>
        MechArcade is an NFT project started by Neon Dreams Studio, LLC, who at
        their heart are world builders, gamers and Metaverse maxis. We genuinely
        appreciate your early support of our project and are humbled that you
        are here to build with us.{" "}
      </p>
      <p>
        It’s unfortunate that these terms of service are necessary, but the laws
        of the land we are inextricably tied via meatspace demand we include
        this TOS before our launch.{" "}
      </p>
      <p>
        Our intention is to make this project open and transparent, and to
        foster the creative spirit that we hope that you will bring to the
        MechArcade. We are builders, collectors and NFT enthusiasts just like
        you are.{" "}
      </p>
      <p>
        Join the MechArcade discord server, contact one of our team members, and
        we will work to address any questions or concerns you might have.
      </p>
      <h2>What We Own</h2>
      <p>
        MechArcade is owned and operated by Neon Dreams Studio LLC, 1309 Coffeen
        Avenue Ste 3635, Sheridan, WY 82801.
      </p>
      <p>
        All rights that are not specifically granted to the users and owners of
        MechArcade NFTs are reserved by the Company. This includes but is not
        limited to the intellectual property rights surrounding the MechArcade
        names, logos, trademarks, the website, the look and feel of the user
        interface, the smart contract code, the generative traits in general,
        and any future community wallet.
      </p>
      <p>
        In addition to owning the IP surrounding the name MechArcade, we reserve
        the exclusive right to use MechArcade official logos for future
        merchandising purposes.
      </p>
      <h2>You Own Your Mech</h2>
      <p>
        The initial product launch of MechArcade is a run of 10,000 individual
        ERC-721 tokens with generative traits that correspond to pieces of art
        hosted on IFPS.
      </p>
      <p>
        By connecting your ethereum wallet and minting a Mech with our smart
        contract, you gain full and complete ownership of your NFT.
      </p>
      <h3>You Own Exclusive Commercial Rights to Your Mech</h3>
      <p>
        You will own full commercial rights to your Mech and the related 3D
        assets, video files, and sound files, so long as you continue to own and
        control the NFT. You may assign some of these rights to other commercial
        projects (e.g. Punks Comics, Boring Coffee Company), but any future
        transfer of your NFT will be subject to those rights that you have
        already assigned. We encourage you to put your Mech on clothing and
        merchandise, include them in video games and video projects, and
        generally do whatever you want with your Mech and related files. You
        cannot use the name MechArcade; on any commercial product unless
        connected directly to a # and the number/name of your Mech, signifying
        the NFT you own. You agree not to use your Mech in any project or
        derivative work that involves hate speech, racism, pornography, or any
        other illegal content.
      </p>
      <h3>You Can Make Derivative Works</h3>
      <p>
        This core of this new frontier of web3 is that of digital property
        rights. As such, we want you to feel empowered to create new projects
        and uses for your Mechs. To give you the greatest amount of flexibility
        possible, we intend after launch to give you access to all 3D assets,
        high-quality video files, music tracks, and 3D-printable formats.
      </p>
      <p>
        You have the right to make derivative works of your Mech so long as you
        own the Mech at the time of creation. Additionally, you may assign those
        rights to other artists, creatives, or third-party projects so long as
        you own the Mech at the time you grant such permissive use.
      </p>
      <h3>Many Third Party Uses Are Not Restricted</h3>
      <p>
        We designed and built these Mechs with the intention of making them easy
        to use in future third party projects. Nothing in these terms are meant
        to limit you or a third party from: (i) owning or operating an NFT
        marketplace that permits the use or sale of the Mechs, so long as the
        marketplace cryptographically verifies the ownership of the NFT to
        ensure that only the owner can use and display their Mech; and (ii)
        owning or operating a third party video game, website or application
        that permits the inclusion or integration of the Mechs generally,
        provided that the website or application verifies the ownership of the
        NFT to ensure that only the owner can use and display their Mech.
        Furthermore, nothing in this TOS is meant to restrict a third party
        website’s ability to build tools which track traits or sales.
      </p>
      <p>
        We have worked very hard to make sure that there are no bugs in our
        smart contract and that our initial NFT launch is smooth. As we have
        seen in many previous projects, however, sometimes things break. You
        agree to hold the Company harmless for any losses you may incur as a
        consequence of minting your NFT. These potential losses include any gas
        fees for failed transactions, any excessive gas fees charged due to
        website or smart contract bugs, and any loss of your NFT due to website
        or smart contract bugs.
      </p>
      <h2>No Guarantees or Future Promises</h2>
      <p>
        MechArcade hopes to develop this project and community. We have released
        a roadmap outlining some of the future goals we hope to accomplish.
        While we intend to work towards those goals, sometimes things happen and
        we cannot guarantee that this original project will sell out entirely
        nor can we guarantee future developments. You agree that your purchase
        of a Mech from our initial project is all you are guaranteed to receive
        with your initial purchase. Any future potential airdrops, community
        gatherings, play2earn games, tokenomics, discord server access or other
        benefits are ancillary to this purchase and not to be taken into
        consideration with your initial purchase. You agree that you are not
        relying on any future commitments by MechArcade in using this site and
        participating in our NFT launch.
      </p>
      <h3>Mechs Are Not Intended as Investments</h3>
      <p>
        MechArcade NFTs are meant to be a fun avatar and Non-Fungible Token for
        you to collect. They are not meant as investment vehicles. We make
        absolutely no promise or guarantee that these NFTs will be worth
        anything. You understand that they have no inherent monetary value, and
        they should be treated as nothing more than a fun collectible.
      </p>
      <h2>Taxes</h2>
      <p>
        You are entirely responsible for any tax liability which may arise from
        minting or reselling your Mechs.
      </p>
      <p>
        You agree to waive any class action status, and any legal dispute around
        the MechArcade project which you may choose to bring can only be done on
        an individual basis.
      </p>
      <h3>Children</h3>
      <p>
        The MechArcade project is not targeted towards children. You agree that
        you are over the age of 18, or above the legal age of your jurisdiction,
        whichever is greater.
      </p>
      <h3>Arbitration</h3>
      <p>
        Any controversy or claim arising out of or relating to the SBS NFT
        project shall be settled by arbitration administered by the American
        Arbitration Association in accordance with its Commercial Arbitration
        Rules, and judgment on the award rendered by the arbitrator(s) may be
        entered in any court having jurisdiction thereof.
      </p>
      <h3>Jurisdiction and Choice of Law</h3>
      <p>
        You agree that for purposes of any legal dispute, you will be subject to
        the personal jurisdiction of Clark County, NV, and any litigation or
        other legal proceeding will be brought in that jurisdiction.
      </p>
      <h2>Privacy Policy</h2>
      <p>
        You understand that the Ethereum blockchain network is a public
        blockchain, and all of your transaction history initiated through the
        website will be made public. You also understand that MechArcade may
        work with third-party apps, such as Discord or Collab.Land, which
        collect and store user data. MechArcade may now or in the future also
        store cookies, browser activity, emails, wallet actions, Social Media
        usernames and activity, physical addresses, and Discord/Twitter/Twitch
        chat activity. Please refer to our privacy policy for a full description
        of what we collect, what is stored, and how we use it.
      </p>
      <h4>
        Thank you for being a part of our launch. See you on Discord and happy
        minting!
      </h4>
    </Container>
  );
};

export default TermsOfService;
