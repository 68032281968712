import styled from "styled-components";

export const Container = styled.section`
  max-width: 110vw;
  height: fit-content;
  position: relative;
  text-align: center;
  margin-bottom: calc(var(--margin) + 80px);
  display: flex;
  justify-content: center;
  background: #000;
  .hero {
    width: auto;
    height: 100vh;
    object-fit: cover;
    margin: 0 auto;
  }
  &:after {
    content: " ";
    position: absolute;
    display: block;
    bottom: -80px;
    width: 100%;
    height: 80px;
    left: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.8) 30%,
      rgba(0, 0, 0, 0.6) 45%,
      rgba(0, 0, 0, 0.4) 60%,
      rgba(0, 0, 0, 0.2) 80%,
      rgba(0, 0, 0, 0.05) 100%
    );
    box-shadow: 0px 5px 40px 0px #00000005;
    z-index: 2;
  }`
  
export const DescContainer = styled.section`
  width: 71vw;
  min-height: 85vh;
  margin: var(--margin) auto;
  margin-top: 9vh;
  background: linear-gradient(
    146deg,
    rgba(22, 20, 20, 0.5) 27%,
    rgba(49, 64, 97, 0.25) 65%,
    rgba(97, 109, 115, 0.2) 91%
  );
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border: 2px solid #505050;
  // -webkit-box-shadow: 0px 0px 30px 2px #87002b;
  // box-shadow: 0px 0px 30px 2px #87002b;  
  border-radius: 10px;
  display: block;
  text-align: left;
  h1 {
    font-family: "Sarpanch", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 90px;
    color: #f5f5f5;
    margin: 2rem auto 3rem;
    text-align: center;
    text-shadow: var(--text-shadow);
  }
  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 25px;
    color: #f5f5f5;
    margin: 25px auto 25px 60px;
    width: 30%;
  }

  @media screen and (max-width: 1500px) and (min-width: 1000px) {
    h1 {
      margin: 1rem auto 2rem;
    }
  }
  @media screen and (max-width: 1000px) {
    width: 90vw;
    background: linear-gradient(
      146deg,
      rgba(22, 20, 20, 0.5) 27%,
      rgba(49, 64, 97, 0.25) 65%,
      rgba(97, 109, 115, 0.2) 91%
    ) !important;
    backdrop-filter: blur(15px) !important;
    -webkit-backdrop-filter: blur(15px) !important;
    border: 1px solid #505053;
    border-radius: 2px;

    min-height: 55vh;
    h1 {
      font-size: 28px;
      line-height: 40px;
      margin: 2rem 1rem 2rem;
    }
    p {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      line-height: 20px;
      margin: 25px auto 25px 40px;
      width: calc(100% - 80px);
      &:last-of-type {
        margin: 0 auto 2rem 40px;
      }
    }
  }
`;
