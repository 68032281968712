import React, { useEffect, useState } from "react";
import {
  SecondContentContainer,
} from "./MintStyle";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
} from "../../utils/interact";
import "../../index.css";
import { Flex, Price } from "../Hero/HeroStyles";
const Mint = () => {
  //
  //
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [txt, setTxt] = useState("");
  const [count, setCount] = useState(5);
    // eslint-disable-next-line
  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    addWalletListener();
  }, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async (e) => {
    e.preventDefault();
    setTxt("");
    const { success, status, txt } = await mintNFT(count);
    setStatus(status);
    if (txt) {
      setTxt(txt);
    }
    if (success) {
      setCount(1);
    }
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          console.log("____________ got wallet" + walletAddress);
          setStatus("Write a message in the text-field above.");
        } else {
          setWallet("");
          console.log("____________ failed to get wallet");
          setStatus("Connect to your ethereum wallet using button above.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://metamask.io/download.html`}
          >
            You must install a virtual Ethereum wallet in your
            browser.
          </a>
        </p>
      );
    }
  }
  const changeHandler = (e) => {
    console.log("value changed: ", e.target.value);
    const num = parseInt(e.target.value);
    setCount(e.target.value);
  };

  useEffect(() => {
    console.log("#########$$$$$$ walletAddress: " + walletAddress);

    if (walletAddress !== "") {
      setStatus(" ");
    }
  }, [walletAddress]);

  let saleDate = new Date("January 25, 2022");
  saleDate =
    saleDate.getFullYear() +
    "-" +
    (saleDate.getMonth() + 1) +
    "-" +
    saleDate.getDate();

  let today = new Date();
  today =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  function isDateBeforeToday() {
    return today < saleDate;
  }

  return (
    <>
          <SecondContentContainer>
          <Flex>
          <Price>
            10,000<span>NFTs</span>
          </Price>
          <div>
              <input
                type="number"
                min={1}
                max={20}
                maxLength={1}
                value={count}
                onChange={changeHandler}
              />
              <button className="discord" type="submit" onClick={onMintPressed}>
                Mint
              </button>
              <p className="status">{status}</p>
              {txt ? (
                <a href={"https://etherscan.io/tx/" + txt}>
                  Click Here to View the Transaction on Etherscan
                </a>
              ) : null}
          </div>
          <Price>
            0.08<span>ETH</span>
          </Price>
        </Flex>
          </SecondContentContainer>
    </>
  );
};

export default Mint;