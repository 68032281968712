import React from "react";
import { Container, ItemCarousel, VideosContainer } from "./RarityStyles";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import image1 from "../../images/CAROUSEL/3.png";
import image2 from "../../images/CAROUSEL/crab22.png";
import image3 from "../../images/CAROUSEL/1.png";

const Rarity = () => {
  const data = [
    {
      img: image1,
      title: "Champion Mechs",
      speed: 36,
      handling: 26,
      jump: 38,
    },
    {
      img: image3,
      title: "SuperRare Mechs",
      speed: 43,
      handling: 33,
      jump: 24,
    },
    {
      img: image2,
      title: "Legendary Mechs",
      speed: 50,
      handling: 18,
      jump: 33,
    },
  ];
  return (
    <Container name="mechs">
      <h1>Meet the Mechs</h1>
      <p>
        Each Mech is a unique 3 dimensional champion rendered from a combination
        of over 160 different properties including battle ready builds, slick
        paint designs, handheld items with game mechanic traits and stats.
      </p>
      <p>
        Every Genesis Mech is unique.
        <br />
        Some are Rare.
        <br />
        Few are Legendary.
      </p>
      <div className="carousel-container">
        {data.map((item, index) => {
          return (
            <ItemCarousel
              key={index}
              speed1={index === 0 && item.speed}
              speed2={index === 1 && item.speed}
              speed3={index === 2 && item.speed}
              handlign1={index === 0 && item.handling}
              handlign2={index === 1 && item.handling}
              handlign3={index === 2 && item.handling}
              jump1={index === 0 && item.jump}
              jump2={index === 1 && item.jump}
              jump3={index === 2 && item.jump}
            >
              <div className="glow">
                <h2>{item.title}</h2>
                <img src={item.img} alt={item.title} />
                {/* <div className="item_stat_flex">
                  <p>Strength </p>
                  <div className="stats_bar">
                    <div
                      style={{ width: `${item.speed}%` }}
                      className={`stats_bars_amount speed${index}`}
                    />
                  </div>
                  <p>{item.speed}%</p>
                </div>
                <div className="item_stat_flex">
                  <p>Speed</p>
                  <div className="stats_bar">
                    <div
                      style={{ width: `${item.handling}%` }}
                      className={`stats_bars_amount handling${index}`}
                    />
                  </div>
                  <p>{item.handling}%</p>
                </div>
                <div className="item_stat_flex">
                  <p>Agility</p>
                  <div className="stats_bar">
                    <div
                      style={{ width: `${item.jump}%` }}
                      className={`stats_bars_amount jump${index}`}
                    />
                  </div>
                  <p>{item.jump}%</p>
                </div> */}
              </div>
            </ItemCarousel>
          );
        })}
      </div>
    </Container>
  );
};

export default Rarity;
