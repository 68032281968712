import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import CloudsComponent from "./components/Clouds";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Whitelist from "./components/whitelist/Whitelist";
import Mint from "./components/mint/Mint";
import Privacy from "./components/Privacy/Privacy";
import Page404 from "./components/404/404";
import Extinct from "./components/Extinct/Extinct";
import Rarity from "./components/Rarity/Rarity";
import Team from "./components/Team/Team";
import Vision from "./components/Vision/Vision";
import ScrollToTop from "./components/ScrollToTop";
import TermsOfService from "./components/TermsOfService/TermsOfService";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <CloudsComponent />
              <Header />
              );
              <Hero></Hero>
              <Extinct />
              <Rarity />
              <Vision />
              <Team />
              <Footer />
            </Route>
            <Route exact path="/whitelist">
              <CloudsComponent />
              <Whitelist />
            </Route>
            <Route exact path="/mint">
              <CloudsComponent />
              <Mint />
            </Route>
            <Route exact path="/privacypolicy">
              <CloudsComponent />
              <Header backhome={true} />
              <Privacy />
              <Footer />
            </Route>
            <Route exact path="/termsofservice">
              <CloudsComponent />
              <Header backhome={true} />
              <TermsOfService />
              <Footer />
            </Route>
            <Route path="*" exact={true}>
              <CloudsComponent />
              <Header backhome={true} />
              <Page404 />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
