import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: var(--white);
  h1 {
    font-size: 12rem;
    line-height: 110%;
    margin: 1rem 0;
    font-family: "Titillium Web", sans-serif;
    text-shadow: 3px 3px 5px #ce5937;
  }
  h2 {
    font-size: 3rem;
    line-height: 110%;
    margin: 1rem 0;
  }
  p {
    line-height: 140%;
    letter-spacing: 1px;
    width: 30%;
    margin-bottom: 2rem;
  }
  a {
    color: #f5f5f5;
    font-family: "Titillium Web", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    width: 180px;
    height: 45px;
    background: transparent;
    letter-spacing: 2px;
    cursor: pointer;
    transition: 0.3s;
    outline: 0;
    border: 1px solid var(--white);
    z-index: 1;
    width: 180px;
    margin-left: 2rem;
    position: relative;
    text-decoration: none;
    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: #fff;
      transition: all 0.3s;
    }
    &:hover {
      color: #000;
      &:after {
        left: 0;
        width: 180px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    h1 {
      font-size: 10rem;
      line-height: 110%;
      margin: 1rem 0;
      font-family: "Titillium Web", sans-serif;
      text-shadow: 3px 3px 5px #ce5937;
    }
    h2 {
      font-size: 2rem;
      line-height: 110%;
      margin: 1rem 0;
    }
    p {
      width: 80%;
    }
  }
`;
