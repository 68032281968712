import React, { useEffect, useState } from "react";
import {
  Container,
  ContentContainer,
  FirstContentContainer,
  HeaderWrapper,
  SecondContentContainer,
  WrapperContainer,
} from "./WhitelistStyle";
import axios from "axios";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
} from "../../utils/interact";
import WhitelistNav from "./WhitelistNav";
import foreground from "../../images/whitelistForeground.png";
import user from "../../images/logoImage.png";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { FaDiscord } from "react-icons/fa";
import { IoWallet } from "react-icons/io5";
import trophy from "../../images/trophy.svg";
import ray from "../../images/ray.svg";

const Whitelist = () => {
  //
  //
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [count, setCount] = useState(1);
  const [discord, setDiscord] = useState("");
  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    addWalletListener();
  }, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async (e) => {
    e.preventDefault();
    const { success, status } = await mintNFT(count, walletAddress);
    setStatus(status);
    if (success) {
      setCount(1);
    }
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          //  setStatus("Write a message in the text-field above.");
        } else {
          setWallet("");
          // setStatus("Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://metamask.io/download.html`}
          >
            You must install a virtual Ethereum wallet in your
            browser.
          </a>
        </p>
      );
    }
  }
  const handleValue = (amount) => {
    if (amount === "") {
      return 1;
    }
    if (amount !== 1 || amount !== 2) {
      return 2;
    } else {
      return amount;
    }
  };
  //
  //
  const CLIENT_ID = process.env.REACT_APP_DISCORD_OAUTH_CLIENT_ID;
  const redirect = encodeURIComponent(
    process.env.REACT_APP_DISCORD_OAUTH_REDIRECT_URL
  );
  let code = window.location.search.slice(6);
  console.log("########## code1: " + code);
  const [discordInfo, setDiscordInfo] = useState();
  const [checkWhitelist, setCheckWhitelist] = useState("not-connected");
  // eslint-disable-next-line
  const [receivedSaleData, setReceivedSaleData] = useState();
  const [receivedSingleData, setReceivedSingleData] = useState();
  const [access_token, setAccess_token] = useState("");
  const [allowedDateToMint, setAllowedDateToMint] = useState(false);
  useEffect(() => {
    if (code && access_token) {
      try {
        const fetchData = async () => {
          await axios.post(
            process.env.REACT_APP_API_URL + "/oauthConnect?code=" + code,
            "",
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );
        };
        fetchData();
      } catch (error) {
        console.log(error);
      }
    }
  }, [code, access_token]);
  //
  //
  //
  const verifyWallet = async (saleId) => {
    console.log("##########Wallet get");
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/wallet?saleId=" + saleId,
      {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    );
  };

  // const verifyWallet = async () => {
  //   const response = await axios.get(process.env.REACT_APP_API_URL + "/wallet");
  //   response.data.map((item) => {
  //     if (item.wallet === walletAddress) {
  //       setReceivedSingleData(item);
  //     }
  //   });
  // };

  // Call to the backend api with get sale data
  useEffect(() => {
    const fetchData = async () => {
      let code2 = window.location.search.slice(6);
      console.log("#########$$$$$$ code2: " + code2);
      //  const response = await axios.get(process.env.REACT_APP_API_URL + "/sale");
      // setReceivedSaleData(response.data);

      //dob
      const createWallet2 = async (wallet) => {
        console.log("##########Wallet post");
        const payload = {
          walletAddress: wallet,
          signedAddress: code2,
        };
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/wallet",
          '{ "walletAddress1" : "' + wallet + '" }',
          // payload.toString(),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setAccess_token(response.data.data);
      };
    };
    fetchData();
  }, [walletAddress]);
  // Call to the backend api with get sale data

  // Cheking to see if current user date is before the sale date
  // determine todays date vs public sale date.
  let saleDate = new Date("January 25, 2022");
  saleDate =
    saleDate.getFullYear() +
    "-" +
    (saleDate.getMonth() + 1) +
    "-" +
    saleDate.getDate();

  let today = new Date();
  today =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  function isDateBeforeToday() {
    return today < saleDate;
  }
  useEffect(() => {
    setAllowedDateToMint(isDateBeforeToday);
  }, []);
  // Cheking to see if current user date is before the sale date

  // Call post create wallet API
  useEffect(() => {
    const createWallet = async (wallet) => {
      let code2 = window.location.search.slice(6);
      console.log("^^^^^^^^^^^^^^^^^^^^^Wallet post: " + code2);

      if (code2.includes("error_description") || code2 === "") {
        console.log("^^^^^^^^^^^^^^^^^^^^^ Aborted");
      } else {
        console.log("^^^^^^^^^^^^^^^^^^^^^ proceding");
      }
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/wallet",
        `{"walletAddress":"${wallet}", "discordCode":"${code2}"}`,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // setAccess_token(response.data.data);
      console.log(response.data.msg);

      if (response.data.msg === "success") {
        console.log("******Whitelisted");
        setCheckWhitelist("whitelisted");
      } else if (response.data.msg === "failure") {
        console.log("******Not White Listed");
        setCheckWhitelist("not-whitelisted");
      }
    };

    if (walletAddress !== "") {
      createWallet(walletAddress);
    }
  }, [walletAddress, discordInfo]);
  // Call post create wallet API
  //
  //
  // Setting up the showing state above the buttons
  useEffect(() => {
    if (walletAddress) {
      setCheckWhitelist("checking");
      if (receivedSingleData) {
        setCheckWhitelist(
          receivedSingleData.discordID === ""
            ? "connectDiscord"
            : receivedSingleData.allow_to_mint === "Not Allowed"
            ? "not-whitelisted"
            : "whitelisted"
        );
      } else if (!discordInfo) {
        setCheckWhitelist("connectDiscord");
      }
    } else {
      setCheckWhitelist("not-connected");
    }
  }, [walletAddress, receivedSingleData]);

  /*
  useEffect(() => {
    if (walletAddress && access_token !== "") {
      verifyWallet(process.env.REACT_APP_SALE_ID);
    }
  }, [walletAddress, access_token]);
  */

  return (
    <>
      <WrapperContainer></WrapperContainer>
      <WhitelistNav />
      <Container>
        <HeaderWrapper>
          <img src={foreground} className="foreground" alt="foreground" />
          <div className="user">
            <img src={user} alt="user" />
          </div>
        </HeaderWrapper>
        <ContentContainer>
          <FirstContentContainer>
            <h1>Whitelist Minting Portal for MECHARCADE </h1>
            <h3>This is the private whitelist portal for the MechArcade</h3>
            <p>
              If you have been invited to join this whitelist, you must be
              loved, fren. Whitelist invitees are composed of individuals and
              groups that have shown real commitment to the project in a variety
              of way, including early support of the project, active involvement
              in our developing community, achievement in our discord server
              through faction competitions and games, accruing our community
              token in the discord server: $BOLT, or cultivating awareness for
              the project through twitter and/or introduction to other NFT
              communities and investors.
            </p>
            <h4>
              These accomplishments are established in the following ways:
            </h4>
            <h1>DISCORD</h1>
            <p>
              <img src={trophy} alt="trophy" /> • Early adopters. Being early
              pays. This role was earned by those individuals who took notice of
              our developing community and jumped in early to support and
              promote the project. Early supporters can add their address to the
              whitelist by verifying through DISCORD.
            </p>
            <p>
              <img src={ray} alt="ray" /> • Community Sparks. Bringing value and
              engagement is rewarded. This role was earned by individuals who
              engaged in our community regularly. Community Sparks can add their
              name to the whitelist by verifying their DISCORD.
            </p>
            <p>
              <img src={trophy} alt="trophy" /> • Dutch's Crew. Our founder,
              Dutch Boyd’s crew is iconic within the poker and gaming
              communities at large since 2004. Now continuing to grow with the
              MechArcade community- they are legion and every member has a
              whitelist spot to claim with the MechArcade. Members of Dutch’s
              crew can add their wallet to the whitelist by verifying through
              DISCORD- or whichever social account they use to engage with Dutch
              and his crew.
            </p>
            <h1>COLLABORATIONS AND SUPPORTERS</h1>
            <p>
              <img src={trophy} alt="trophy" /> • Community Collaborations. Some
              vibrant communities of serious NFT collectors and gamers who take
              notice of new projects have collaborated with the MechArcade
              community. These communities can add their wallet address to the
              whitelist by verifying through DISCORD, or have already had their
              wallet added by the team.
            </p>
            <p>
              <img src={trophy} alt="trophy" /> • Finally, several people have
              earned their place on the whitelist through twitter contests,
              discord contests or bringing awareness to the CT community at
              large. In which case, the team has likely added your wallet to the
              whitelist manually. This should reflect on the whitelisting panel
              to the right when you have connected your wallet. If you have been
              whitelisted by the team and do not see that your wallet has been
              whitelisted, please reach out to us on our discord and inquire so
              that we can add you.
            </p>
          </FirstContentContainer>
          <SecondContentContainer>
            {checkWhitelist === "not-connected" ? (
              <div className="circle-loader white-complete">
                <IoWallet />
              </div>
            ) : checkWhitelist === "checking" ? (
              <div className="circle-loader"></div>
            ) : checkWhitelist === "not-whitelisted" ? (
              <div className="circle-loader red-complete">
                <MdClose className="close_icon" />
              </div>
            ) : checkWhitelist === "whitelisted" ? (
              <div className="circle-loader load-complete">
                <div className="checkmark draw"></div>
              </div>
            ) : checkWhitelist === "connectDiscord" ? (
              <div className="circle-loader white-complete">
                <FaDiscord />
              </div>
            ) : (
              <BsCheckCircleFill />
            )}

            {checkWhitelist === "not-connected" ? (
              <h2>
                Please connect your wallet and verify the account in order to be
                added to the whitelist.
              </h2>
            ) : checkWhitelist === "checking" ? (
              <h2>Checking...</h2>
            ) : checkWhitelist === "not-whitelisted" ? (
              <h2>
                You do not have the necessary roles to be on the whitelist.
              </h2>
            ) : checkWhitelist === "whitelisted" ? (
              <h2>Congratulations, wallet is on the whitelist</h2>
            ) : checkWhitelist === "connectDiscord" ? (
              <h2>Please, connect with discord.</h2>
            ) : (
              <h2>There was some error</h2>
            )}

            <button
              className="connect"
              onClick={connectWalletPressed}
              disabled={walletAddress.length > 0 ? true : false}
              style={{
                cursor: walletAddress.length > 0 ? "not-allowed" : "pointer",
              }}
            >
              {" "}
              {walletAddress.length > 0
                ? String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                : "Connect Wallet"}
            </button>
            {walletAddress ? (
              <a
                href={`https://discord.com/api/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${redirect}&response_type=code&scope=identify%20email%20guilds%20guilds.members.read`}
                className="discord"
              >
                {receivedSingleData
                  ? receivedSingleData.allow_to_mint === "Allowed"
                    ? receivedSingleData.name
                    : discordInfo &&
                      receivedSingleData.allow_to_mint === "Allowed"
                    ? `
                  ${discordInfo.username}#${discordInfo.discriminator}
                  `
                    : "Verify Discord"
                  : "Verify Discord"}
              </a>
            ) : (
              <button className="discord">Verify Wallet First</button>
            )}
            {receivedSingleData ? (
              receivedSingleData.allow_to_mint === "Allowed" &&
              !allowedDateToMint ? (
                <form>
                  {receivedSingleData.mint > 4 ? (
                    <>
                      <input
                        type="text"
                        placeholder="Minted 5 mechs"
                        style={{ cursor: "not-allowed" }}
                      />
                      <button
                        className="mint_button"
                        type="submit"
                        style={{ cursor: "not-allowed" }}
                      >
                        CANNOT MINT
                      </button>
                    </>
                  ) : (
                    <>
                      <input
                        type="number"
                        min="1"
                        max={5 - receivedSingleData.mint}
                        value={count}
                        onChange={(e) => setCount(e.target.value)}
                      />
                      <button
                        className="mint_button"
                        type="submit"
                        onClick={onMintPressed}
                      >
                        MINT
                      </button>
                      <p>{status}</p>
                    </>
                  )}
                </form>
              ) : receivedSingleData.allow_to_mint === "Allowed" &&
                allowedDateToMint ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setEmail("");
                  }}
                >
                  <label htmlFor="email">
                    We are not minting yet, add you email to be notificate when
                    we open the mint process.
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button className="mint_button" type="submit">
                    NOTIFICATE
                  </button>
                </form>
              ) : null
            ) : null}
          </SecondContentContainer>
        </ContentContainer>
      </Container>
    </>
  );
};

export default Whitelist;

// Get user avatar => https://cdn.discordapp.com/avatars/{user.id}/{user.avatar}
