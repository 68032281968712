import React from "react";
import { FaDiscord, FaTwitter, FaMediumM } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  FirstContainer,
  FooterContainer,
  FooterWrapper,
  IconFooter,
  IconsContainer,
  LinksContainer,
} from "./FooterStyles";
import Opensea from "../Header/Opensea";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FirstContainer>
          <div>{/* <img src={LogoImage} alt="Mechaverse Logo" /> */}</div>

          <p>Copyright 2021 Neon Dreams Studio LLC</p>
        </FirstContainer>
        <FirstContainer>
          <LinksContainer>
            <Link to="/">Home</Link>
            <Link to="/termsofservice">Terms & Conditions</Link>
            <Link to="/privacypolicy">Privacy Policy</Link>
          </LinksContainer>
          <IconsContainer>
          <IconFooter href="https://opensea.io/collection/mecharcadeofficial">
              <Opensea />
            </IconFooter>
            <IconFooter href="https://twitter.com/MechArcade">
              <FaTwitter />
            </IconFooter>
            <IconFooter href="https://discord.gg/MechArcade" target="_blank">
              <FaDiscord />
            </IconFooter>
            <IconFooter
              href="https://medium.com/@mecharcadeofficial"
              target="_blank"
            >
              <FaMediumM />
            </IconFooter>
          </IconsContainer>
        </FirstContainer>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
