import styled from "styled-components";

export const Container = styled.section`
  width: var(--container-width);
  min-height: 65vh;
  margin: 6.5rem auto;
  display: block;
  text-align: left;
  font-family: "Sarpanch", sans-serif;
  text-align: center;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 36px;
    letter-spacing: 2px;
    color: #f5f5f5;
    margin: 3rem auto 2rem;
    text-shadow: var(--text-shadow);
  }
  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 140%;
    text-align: center;
    color: #f5f5f5;
    text-align: center;
    width: 65%;
    margin: 1rem auto;
  }
  .carousel-container {
    display: flex;
    width: 90%;
    margin: 2rem auto;
  }
  @media screen and (max-width: 1500px) and (min-width: 1100px) {
    p,
    .carousel-container {
      width: 100%;
    }
  }
  @media screen and (max-width: 1100px) {
    width: 90vw;
    margin: 4rem auto;
    .carousel-container {
      display: block;
    }
    h1 {
      font-size: 28px;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const ItemCarousel = styled.div`
  width: 50%;
  margin: 0 auto;
  .glow {
    border-radius: 25px;
    padding-top: 1rem; 
}
  img {
    width: 22vw;
    object-fit: cover;
    margin-bottom: -30px;
    padding-top: 1rem;
  }
  h2 {
    font-family: "Sarpanch", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 18px;
    color: #f5f5f5;
    margin: 1rem 0 .25rem;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 90%;
    color: #f5f5f5;
    margin: 1rem auto 1rem auto;
    text-align: center;
    width: 90%;
  }
  .item_stat_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    p {
      color: #fff;
      font-size: .85rem;
      letter-spacing: 2px;
      width: 4vw;
      margin: 0;
      font-weight: bold;
      text-align: right;
      &:last-of-type {
        text-align: left;
        width: fit-content;
      }
    }
    .stats_bar {
      height: 10px;
      width: 12vw;
      background: #545454;
      border-radius: 25px;
      position: relative;
      margin: 0 1rem;
    }
    .stats_bars_amount {
      height: 100%;
      background: #fff;
        -webkit-box-shadow: 0px 0px 30px 2px #87002b;
        box-shadow: 0px 0px 30px 2px #87002b;    
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px;
    }
    .speed0 {
      animation: grow1 2s linear;
      @keyframes grow1 {
        0% {
          width: 0%;
        }
        100% {
          width: ${({ speed1 }) => `${speed1}%`};
        }
      }
    }
    .speed1 {
      animation: grow2 2s linear;
      @keyframes grow2 {
        0% {
          width: 0%;
        }
        100% {
          width: ${({ speed2 }) => `${speed2}%`};
        }
      }
    }
    .speed2 {
      animation: grow3 2s linear;
      @keyframes grow3 {
        0% {
          width: 0%;
        }
        100% {
          width: ${({ speed3 }) => `${speed3}%`};
        }
      }
    }
    .handling0 {
      animation: handling1 2s linear;
      @keyframes handling1 {
        0% {
          width: 0%;
        }
        100% {
          width: ${({ handling1 }) => `${handling1}%`};
        }
      }
    }
    .handling1 {
      animation: handling2 2s linear;
      @keyframes handling2 {
        0% {
          width: 0%;
        }
        100% {
          width: ${({ handling2 }) => `${handling2}%`};
        }
      }
    }
    .handling2 {
      animation: handling3 2s linear;
      @keyframes handling3 {
        0% {
          width: 0%;
        }
        100% {
          width: ${({ handling3 }) => `${handling3}%`};
        }
      }
    }
    .jump0 {
      animation: jump1 2s linear;
      @keyframes jump1 {
        0% {
          width: 0%;
        }
        100% {
          width: ${({ jump1 }) => `${jump1}%`};
        }
      }
    }
    .jump1 {
      animation: jump2 2s linear;
      @keyframes jump2 {
        0% {
          width: 0%;
        }
        100% {
          width: ${({ jump2 }) => `${jump2}%`};
        }
      }
    }
    .jump2 {
      animation: jump3 2s linear;
      @keyframes jump3 {
        0% {
          width: 0%;
        }
        100% {
          width: ${({ jump3 }) => `${jump3}%`};
        }
      }
    }
  }
  @media screen and (max-width: 1500px) and (min-width: 1100px) {
    .item_stat_flex {
      p {
        width: 5vw;
      }
    }
  }
  @media screen and (max-width: 1100px) and (min-width: 500px) {
    width: 100% !important;
    margin: 0 auto 2rem;
    img {
      width: 75vw;
    }
    h2 {
      margin: 0.5rem 0 0.25rem;
    }
    p {
      width: 80%;
      margin: 0 auto;
      text-align: center;
    }
    .item_stat_flex {
      .stats_bar {
        width: 70vw;
      }
      p {
        width: 8vw;
      }
    }
  }
  @media screen and (max-width: 500px) {
    width: 100% !important;
    margin: 0 auto 2rem;
    img {
      width: 75vw;
    }
    h2 {
      margin: 0.5rem 0 0.25rem;
    }
    p {
      width: 80%;
      margin: 0 auto;
      text-align: center;
    }
    .item_stat_flex {
      .stats_bar {
        width: 50vw;
        height: 15px;
      }
      p {
        text-align: left;
        width: 15vw;
        font-size: 0.8rem;
      }
    }
  }
`;

export const VideosContainer = styled.div`
  width: 80%;
  margin: 2rem auto;
  display: flex;
  @media screen and (max-width: 1000px) {
    display: block;
  }
`;
