import styled from "styled-components";

export const Container = styled.section`
  width: 50vw;
  min-height: 65vh;
  margin: var(--margin) auto;
  display: block;
  text-align: left;
  background: linear-gradient(
    146deg,
    rgba(22, 20, 20, 0.5) 27%,
    rgba(49, 64, 97, 0.25) 65%,
    rgba(97, 109, 115, 0.2) 91%
  );
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border: 2px solid #505050;
  border-radius: 10px;
  font-family: "Sarpanch", sans-serif;
  text-align: center;
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 36px;
    letter-spacing: 2px;
    color: #f5f5f5;
    margin: 3rem auto 2rem;
    text-shadow: var(--text-shadow);
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    h1 {
      font-size: 28px;
      line-height: 40px;
    }
  }
`;

export const ItemCarousel = styled.div`
  width: 50%;
  margin: 0 auto;
  img {
    width: 195px;
    height: 210px;
    // border: 2px solid #5e0112;
    object-fit: cover;
    margin-bottom: 0;
  }
  h2 {
    font-family: "Sarpanch", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 34px;
    color: #f5f5f5;
    margin: 0.75rem 0 0.5rem;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #f5f5f5;
    margin: 0.5rem auto 0.5rem auto;
    text-align: center;
    width: 90%;
  }
  @media screen and (max-width: 1000px) {
    width: 100% !important;
    margin: 0 auto 2rem;
    h2 {
      margin: 0.5rem 0 0.25rem;
    }
    p {
      font-family: "Roboto", sans-serif;
      width: 80%;
      margin: 0 auto;
      text-align: center;
    }
  }
`;

export const VisionContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: flex-start;
  align-items: center;

  justify-content: space-evenly;
  margin: 3rem auto 4rem;
  .burnout_container {
    p {
      color: #eb2a6a;
      margin: 0.75rem 0 auto;
      font-size: 17px;
      font-family: "Sarpanch";
    }
  }
  .container {
    width: 50%;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 26px;
    color: #f5f5f5;
    margin: 0 0 0.5rem 0;
    text-align: center;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: light;
    font-size: 17px;
    line-height: 18px;
    color: #f5f5f5;
    margin: 0;
    text-align: center;
    width: 100% !important;
  }
  @media screen and (max-width: 1000px) {
    align-items: center;
    width: 85%;
    justify-content: flex-start;
    .burnout {
      width: 100px;
      height: 100px;
      border-radius: 15px;
    }
    .container {
      width: 100%;
      margin-left: 25px;
    }
    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;
      color: #f5f5f5;
      margin: 0 0 0.5rem 0;
      text-align: center;
    }
    p {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
    }
    .burnout_container {
      width: 110px;
      p {
        font-size: 14px;
      }
    }
  }
`;

export const BurnoutContainer = styled.div`
  width: 125px;
  height: 125px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #090000;
  border: 2px solid white;
  ${({ shadow }) =>
    shadow
      ? "-webkit-box-shadow: 0px 0px 30px 2px #87002b; box-shadow: 0px 0px 30px 2px #87002b;"
      : null}
  img {
    width: 60%;
  }
  @media screen and (max-width: 1000px) {
    width: 70px;
    height: 70px;
    border-radius: 15px;
  }
`;
