import axios from "axios";

// import { pinJSONToIPFS } from "./pinata.js";
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const contractABI = require("../contract-abi.json");
const contractAddress = "0x66e3ec04d1697f3b405b05d9f1d542303d6383d5"; //"0x4C4a07F737Bf57F6632B6CAB089B78f62385aCaE";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        // status: "👆🏽 Write a message in the text-field above.",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://metamask.io/download.html`}
            >
              You must install a virtual Ethereum wallet in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          //status: "👆🏽 Write a message in the text-field above.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to an ethereum virtual wallet using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://metamask.io/download.html`}
            >
              You must install a virtual Ethereum wallet in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

// async function loadContract() {
//   return new web3.eth.Contract(contractABI, contractAddress);
// }

export const mintNFT = async (count, walletAddress) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  //const nonce = await web3.eth.getTransactionCount(myAddress, 'latest');
  // var theVal = parseInt(count) * 80000000000000000;

  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    //
    value: web3.utils.toHex(80000000000000000 * count),
    data: window.contract.methods
      //.whitelistMint_rhh(count, signedWalletAddress)
      .publicMint_1VS(count)
      .encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    const fetchData = async () => {
      const response = await axios.get(process.env.REACT_APP_API_URL);
      response.data.map(async (item) => {
        if (item.wallet === walletAddress) {
          const article = {
            wallet: walletAddress,
            discordID: item.discordID,
            name: item.name,
            access_token: item.access_token,
            allow_to_mint: item.allow_to_mint,
            reason: item.reason,
            hash: item.hash,
            nonce: item.nonce,
            mint: item.mint + parseInt(count),
          };
          const postData = await axios.put(
            process.env.REACT_APP_API_URL + "/" + item.id,
            article,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }
      });
    };
    fetchData();
    return {
      success: true,
      status: "✅ Check out your transaction on Etherscan: ",
      txt: txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};

export const whitlistmintNFT = async (
  count,
  walletAddress,
  signedWalletAddress
) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  //const nonce = await web3.eth.getTransactionCount(myAddress, 'latest');
  // var theVal = parseInt(count) * 80000000000000000;

  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    //
    value: web3.utils.toHex(80000000000000000 * count),
    data: window.contract.methods
      .whitelistMint_rhh(count, signedWalletAddress)
      .encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    const fetchData = async () => {
      const response = await axios.get(process.env.REACT_APP_API_URL);
      response.data.map(async (item) => {
        if (item.wallet === walletAddress) {
          const article = {
            wallet: walletAddress,
            discordID: item.discordID,
            name: item.name,
            access_token: item.access_token,
            allow_to_mint: item.allow_to_mint,
            reason: item.reason,
            hash: item.hash,
            nonce: item.nonce,
            mint: item.mint + parseInt(count),
          };
          const postData = await axios.put(
            process.env.REACT_APP_API_URL + "/" + item.id,
            article,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }
      });
    };
    fetchData();
    return {
      success: true,
      status: "✅ Check out your transaction on Etherscan: ",
      txt: txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};
