import React from "react";
import { Container } from "../Rarity/RarityStyles";
import digi from "../../images/teamcompressed/avabot.png";
import first from "../../images/teamcompressed/1st-min.jpg";
import cto from "../../images/teamcompressed/cto-min.png";
import dutch from "../../images/teamcompressed/dutch-min.png";
import gabriel from "../../images/teamcompressed/gabriel-min.png";
import gustavo from "../../images/teamcompressed/gustavo-min.png";
import richvagner from "../../images/teamcompressed/cold.png";
import sd from "../../images/teamcompressed/SD-min.png";
import DaveStann from "../../images/advisorscompressed/DaveStann-min.png";
import Roothlus from "../../images/advisorscompressed/Roothlus-min.png";
import MarshallLipman from "../../images/advisorscompressed/Lipmaneth.png";
import Jackie from "../../images/advisorscompressed/Jackieape.png";
import { GridContainer } from "./TeamStyles";
import { TiSocialTwitter } from "react-icons/ti";

const Team = () => {
  const teamMembers = [
    {
      img: gabriel,
      name: "Gabriel",
      desc: "Lead 3D artist",
      twitter: "https://twitter.com/FastestBunny",
    },

    {
      img: dutch,
      name: "Dutch Boyd",
      desc: "Entrepreneur, Lawyer, Professional Poker Player",
      twitter: "https://twitter.com/DutchBoyd",
    },
    {
      img: sd,
      name: "Steve Dolle",
      desc: "Entrepreneur with focus on Advertising & Marketing",
      twitter: "https://twitter.com/SDfromthelou",
    },
    {
      img: first,
      name: "Michele Cosper",
      desc: "Artist - Creative Visionary",
      twitter: "https://twitter.com/1st",
    },
    {
      img: gustavo,
      name: "Gustavo",
      desc: "Artist - Graphic Design",
      twitter: "https://twitter.com/gustneon",
    },
    {
      img: richvagner,
      name: "Nathan Kapusta",
      desc: "Community Management and Marketing",
      twitter: "https://twitter.com/@xcold64x",
    },
    {
      img: cto,
      name: "David O. Berger",
      desc: "Harvard University Alum, 30+ years of experience as CTO and Developer",
      twitter: "https://twitter.com/CTOintheCloud",
    },
    {
      img: Jackie,
      name: "iJockie",
      desc: "NFT Consultant, Technical Background in Customer Success and Project Management",
      twitter: "https://twitter.com/iJackie_eth",
    },
    {
      img: DaveStann,
      name: "Dave Stann",
      desc: "Former Director of Product at Kraken & GSN Games",
      twitter: "https://twitter.com/Hollywood_Dave",
    },
    {
      img: MarshallLipman,
      name: "Marshall Lipman",
      desc: "Previous Head of Business Development at JTV.com",
      twitter: "https://twitter.com/lipmaneth",
    },
    {
      img: Roothlus,
      name: "Adam “Roothlus” Levy",
      desc: "NFT Consultant and Co-founder of Games & Grooves",
      twitter: "https://twitter.com/Roothlus",
    },
  ];

  return (
    <Container name="team">
      <h1>Our Team</h1>
      <GridContainer>
        {teamMembers.map((item, index) => {
          return (
            <div key={index}>
              <div className="item_container">
                <img src={item.img} alt={item.name} />
                <a href={item.twitter}>
                  <TiSocialTwitter />
                </a>
              </div>
              <h2>{item.name}</h2>
              <p>{item.desc}</p>
            </div>
          );
        })}
      </GridContainer>
    </Container>
  );
};

export default Team;
