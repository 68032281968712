import React from "react";
import logoImage from "../../images/logoImage.png";
import { Container, VisionContainer } from "./VisionStyles";
import Fade from "react-reveal/Fade";
import Burnout from "./Burnout";

const Vision = () => {
  const visionData = [
    {
      title: "Genesis Mech Launch",
      desc: "The launch of 10,000 Genesis Mechs on the Ethereum Blockchain.  A 24 hour whitelist mint to save our community gas and accommodate every time zone followed by a Waitlist mint and Public mint.",
      img: logoImage,
    },
    {
      title: "Community Treasury",
      desc: "Establish the community-directed treasury. The funds from the first 10% of Mechs (80 ETH) seed the community-directed treasury with an additional 10% of proceeds added after all 10,000 Mechs have found a home.  An additional 66% of secondary sales also go to the community-directed treasury.",
      img: logoImage,
    },
    {
      title: "MechArcade Lab",
      desc: "Develop and launch the MechArcade Lab.  Initially a portal for Mech owners to download high quality versions of all videos (.mp4 & png), 3D assets, music files (.mp3 & .flac) and 3D-printable (.stl) schematics, the Lab will be expanded to include Mech customizations, upgrades and ever expanding utility.",
      img: logoImage,
    },
    {
      title: "Mech Upgrades",
      desc: "MechArcade Labs will allow the use of the $BOLT token to upgrade mechs. As you level your Mech, you can add points to the individual stats or to a knowledge stat that allows for the creation of Mech part BLUEPRINTS.",
      img: logoImage,
    },
    {
      title: "Tokenomics",
      desc: "Release $BOLT ERC-20 token and allow for discord bolt$ to be cashed in for special access and conversion to the ERC-20 $BOLT token.",
      img: logoImage,
    },
    {
      title: "Blueprints and Building",
      desc: "Fully upgraded and leveled Mechs can be wrecked to create a BLUEPRINT of one of its parts. A BLUEPRINT is an NFT that can be combined with other BLUEPRINTs of complimentary Mech parts to mint a new custom Mech.  WRECKING is quasi-deflationary mechanic that redistributes skill points and moves to newly minted Mech parts.",
      img: logoImage,
    },
    {
      title: "Interoperability",
      desc: "Every unique Mech will be fully rigged for animation and gaming.  The .FBX files of every fully rigged Mech will be made available to Mech holders to import across a varity of game engines and metaverses.  Fight moves will be animated and the metadata will be updated to show the moves.",
      img: logoImage,
    },
    {
      title: "Charity Donation",
      desc: "Donate 10 ETH to Rainforest Foundation US to help offset carbon emissions produced by the sellout of our drop. Our founding artists are from Brazil, which contains the majority of the Amazon Rainforest - the most important rainforest to humanity. Additionally, we will donate another 10 ETH to Take This, Inc. to educate, inform, and advocate for mental health issues within the gaming community.",
      img: logoImage,
    },
    {
      title: "MechArcade PvP Game",
      desc: "After releasing single player games and puzzles for Mech owners to earn $BOLT, we aim to release a multiplayer battle system built on top of the MechArcade game assets. The battle system will be structured as a crossover fighting video game.",
      img: logoImage,
    },
    {
      title: "Guild Lending Program",
      desc: "MechArcade Labs offers a portal for guild creation and automated asset rentals.  Mech holders will be able to offer their assets to new players for a share of $BOLT in a trustless way.",
      img: logoImage,
    },
  ];
  return (
    <Container name="vision">
      <h1>Our Vision</h1>
      {visionData.map((item, index) => {
        if (index < 2) {
          return (
            <VisionContainer key={index} style={{ opacity: "1" }}>
              <div className="burnout_container">
                <Burnout img={item.img} />
                <p>Launched</p>
              </div>
              <Fade right>
                <div className="container">
                  <h2>{item.title}</h2>
                  <p>{item.desc}</p>
                </div>
              </Fade>
            </VisionContainer>
          );
        } else if(index < 4 && index >= 2) {
            return (
              <VisionContainer key={index} style={{ opacity: "1" }}>
                <div className="burnout_container">
                  <Burnout img={item.img} />
                  <p>In Progress</p>
                </div>
                <Fade right>
                  <div className="container">
                    <h2>{item.title}</h2>
                    <p>{item.desc}</p>
                  </div>
                </Fade>
              </VisionContainer>
            );
          } else {
          return (
            <VisionContainer key={index} style={{ opacity: ".5" }}>
              <Burnout img={item.img} />
              <div className="container">
                <h2>{item.title}</h2>
                <p>{item.desc}</p>
              </div>
            </VisionContainer>
          );
        }
      })}
    </Container>
  );
};

export default Vision;
