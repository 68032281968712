import styled from "styled-components";

export const Container = styled.section`
  max-width: 110vw;
  height: fit-content;
  position: relative;
  text-align: center;
  padding-bottom: 9vh;
  display: flex;
  justify-content: center;
  background: #000;
  .hero {
    width: auto;
    height: 90vh;
    object-fit: cover;
    margin: 7.4vh auto;
  }
  @media screen and (max-width: 1440px) {
    min-height: 40vh;
    max-height: calc(60vh + calc(40vh - 160px));
    padding-top: 10vh;
    padding-bottom: 60vh;
    margin-bottom: 9vh;
    .hero {
      width: auto;
      height: 75vh;
      margin: 0;
      max-width: 110%;
    }
  }
`;

export const DescContainer = styled.section`
  width: 90%;
  position: relative;
  max-width: 100%;
  height: fit-content;
  position: absolute;
  left: 5%;
  top: 60%;
  transform: translate(0%, -61%);
  display: block;
  h1 {
    color: #fff;
    font-size: 9vw;
    line-height: 100%;
    text-transform: uppercase;
    font-family: var(--header-font);
    margin: 20rem 0 1.5rem;
    text-align: center;
    text-shadow: var(--text-shadow);
  }
  button {
    font-family: var(--header-font);
    color: #fff;
    font-size: 1rem;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    border: 1px solid #f5f5f5;
    transition: 0.3s;
    background: #000;
    &:hover,
    &:focus {
      color: #000;
      box-shadow: inset calc(30vw) 0 0 0 #f5f5f5;
      svg {
        color: #000;
      }
    }
    svg {
      font-size: 2rem;
      color: var(--white);
      cursor: pointer;
    }
    @media screen and (max-width: 1024px) and (min-width: 500px) {
      width: 200px;
      height: 50px;
      svg {
        font-size: 1.7rem;
      }
    }
  p {
    font-family: "Roboto", sans-serif;
    width: 40%;
    margin: 0 auto;
    font-size: 1.25rem;
    color: #fff;
    text-align: center;
    line-height: 100%;
  }
  @media screen and (max-width: 1450px) and (min-width: 1024px) {
    top: 50%;
    transform: translate(0%, -35%);
    p {
      width: 60%;
      margin: 0 auto 1rem;
      font-size: 1.35rem;
      color: #fff;
      letter-spacing: 1px;
      text-align: center;
    }
  }
  @media screen and (max-width: 1023px) and (min-width: 821px) {
    top: 50%;
    transform: translate(0%, -30%);
    p {
      width: 90%;
      margin: 0.5rem auto;
      font-size: 1.45rem;
    }
  }
  @media screen and (max-width: 821px) and (min-width: 541px) {
    top: 50%;
    transform: translate(0%, -50%);
    p {
      width: 90%;
      margin: 0.5rem auto;
      font-size: 1rem;
    }
  }
  @media screen and (max-width: 541px) {
    top: 50%;
    transform: translate(0%, -15%);
    h1 {
      font-size: 5vw;
      margin: 2rem auto 1rem;
    }
    p {
      width: 90%;
      margin: 0.5rem auto;
      font-size: 0.9rem;
    }
  }
`;

export const Price = styled.div`
  display: grid;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 1px;
  span {
    color: #bebebe;
    font-size: 1.2rem;
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
  @media screen and (max-width: 600px) {
   display: none;
    }
}
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Flex2 = styled.div`
 align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
  div {
   
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 1px;
    span {
      color: #bebebe;
      font-size: 1.2rem;
    }
  }
 
  }
`;
