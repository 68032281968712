import styled from "styled-components";

export const WrapperContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: #2d2d2d;
  z-index: -100;
  position: fixed;
  left: 0;
  top: 0;
`;

export const Container = styled.section`
  width: calc(100% - 20vw);
  min-height: calc(100vh - 200px);
  background: linear-gradient(
    146deg,
    rgba(22, 20, 20, 0.65) 45%,
    rgba(45, 45, 45, 0.6) 65%,
    rgba(80, 80, 80, 0.4) 91%
  );
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border: 1px solid #212828;
  margin: 0 auto;
  margin-top: 150px;
  @media screen and (max-width: 1430px) {
    width: 100%;
  }
`;

export const Wrapper = styled.article`
  width: 45vw;
  padding: 5rem 0;
  color: #fbf0f0;
  text-align: center;
  margin: 0 auto;
  img {
    height: 175px;
    width: auto;
    object-fit: cover;
    margin-bottom: 4rem;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 75px;
    margin: 1rem auto;
    letter-spacing: 2px;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    margin: 1rem auto;
    letter-spacing: 2px;
  }
  .mint_button {
    font-family: "Titillium Web", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    width: 180px;
    height: 45px;
    background: #000;
    letter-spacing: 2px;
    cursor: pointer;
    transition: 0.3s;
    outline: 0;
    border: 1px solid #9bd2d6;
    z-index: 1;
    width: 180px;
    position: relative;
    margin: 2rem auto;
    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: #f5f5f5;
      transition: all 0.3s;
    }
    &:hover {
      color: #000;
      &:after {
        left: 0;
        width: 180px;
      }
    }
  }
  input {
    width: 75%;
    border: 0;
    border-bottom: 3px solid #f5f5f5;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    font-size: 1.1rem;
    background: transparent;
    margin: 1rem auto 1rem;
    &::placeholder {
      font-size: 1.1rem;
    }
    &:focus,
    &:focus-within,
    &:active {
      outline: 0;
    }
  }
  .status {
    height: 2rem;
    font-size: 1rem;
    letter-spacing: 1px;
  }

  @media screen and (max-width: 1000px) {
    width: 90vw;
    img {
      width: 200px;
      height: 200px;
      object-fit: cover;
    }
    h1 {
      font-size: 50px;
      line-height: 64px;
    }
    .connect_button {
      width: 200px;
    }
    .whitelist_button {
      width: 200px;
    }
    input {
      width: 100%;
      padding-left: 1rem;
    }
  }
`;

export const NavContainer = styled.nav`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10vw 0 calc(10vw - 17px);
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  img {
    height: 70px;
    width: auto;
    object-fit: cover;
  }
  @media screen and (max-width: 1000px) {
    padding: 0 5vw;
    img {
      height: 60px;
    }
  }
`;

export const HeaderWrapper = styled.header`
  width: 100%;
  position: relative;
  height: 35vh;
  margin-bottom: 125px;
  .foreground {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .user {
    position: absolute;
    left: 10vw;
    border: 2px solid #cdeaec;
    -webkit-box-shadow: 0px 0px 10px 1px #9bd2d6;
    box-shadow: 0px 0px 10px 1px #9bd2d6;
    bottom: -100px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #212828;
    img {
      width: 70%;
      height: auto;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 1430px) {
    .user {
      left: 5vw;
    }
  }
  @media screen and (max-width: 1000px) {
    margin-bottom: calc((125px / 2));
    .user {
      position: absolute;
      left: 5vw;
      bottom: calc(-125px / 2);
      width: 125px;
      height: 125px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #212828;
      border: 2px solid #cdeaec;
      -webkit-box-shadow: 0px 0px 10px 1px #9bd2d6;
      box-shadow: 0px 0px 10px 1px #9bd2d6;
      img {
        width: 70%;
        height: 70%;
        object-fit: cover;
      }
    }
  }
`;

export const ContentContainer = styled.section`
  width: 100%;
  padding: 0 10vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 1430px) {
    padding-bottom: 2rem;
    padding: 0 5vw;
  }
`;

export const FirstContentContainer = styled.article`
  width: 60%;
  margin-bottom: 3rem;
  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
  }
  h3,
  h4,
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #fbf0f0;
  }
  p {
    color: #e0e0e0;
    letter-spacing: 1px;
    /* text-align: justify; */
  }
  h3 {
    font-weight: 600;
  }
  img {
    width: 15px;
  }
  @media screen and (max-width: 1430px) {
    width: 100%;
    h3,
    h4,
    p {
      font-family: "Roboto", sans-serif;
      font-weight: bold;
      font-size: 15px;
      line-height: 17px;
      color: #fbf0f0;
    }
  }
`;

export const SecondContentContainer = styled.article`
  background: #212828;
  border: 1px solid #7ab7b7;
  box-sizing: border-box;
  border: 2px solid #cdeaec;
  -webkit-box-shadow: 0px 0px 10px 1px #9bd2d6;
  box-shadow: 0px 0px 10px 0.5px #9bd2d6;
  width: 35%;
  min-height: 20vw;
  text-align: center;
  position: sticky;
  top: 10px;
  padding-bottom: 1rem;
  svg {
    width: 7em;
    height: 7em;
    color: #fbf0f0;
    border-radius: 50%;
    margin-top: 2rem;
  }
  /*  */
  .circle-loader {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left-color: #fff;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 50%;
    width: 7em;
    height: 7em;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .red-complete {
    -webkit-animation: none;
    animation: none;
    border-color: #de1107;
    transition: border 500ms ease-out;
  }
  .white-complete {
    -webkit-animation: none;
    animation: none;
    border-color: #fff;
    transition: border 500ms ease-out;
    svg {
      width: 3em;
      margin-top: 0;
    }
  }
  .close_icon {
    height: 4em;
    color: #de1107;
    margin-top: 0;
    animation-duration: 1500ms;
    animation-timing-function: linear;
    animation-name: not-whitelisted;
  }
  @keyframes not-whitelisted {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(3deg);
    }
    20% {
      transform: rotate(-3deg);
    }
    30% {
      transform: rotate(3deg);
    }
    40% {
      transform: rotate(-3deg);
    }
    50% {
      transform: rotate(3deg);
    }
    60% {
      transform: rotate(-3deg);
    }
    70% {
      transform: rotate(3deg);
    }
    80% {
      transform: rotate(-3deg);
    }
    90% {
      transform: rotate(3deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: #5cb85c;
    transition: border 500ms ease-out;
  }
  .checkmark {
    display: block;
  }
  .checkmark.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }
  .checkmark:after {
    opacity: 1;
    height: 3.5em;
    width: 1.75em;
    transform-origin: left top;
    border-right: 3px solid #5cb85c;
    border-top: 3px solid #5cb85c;
    content: "";
    left: 1.75em;
    top: 3.5em;
    position: absolute;
  }
  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: 1.75em;
      opacity: 1;
    }
    40% {
      height: 3.5em;
      width: 1.75em;
      opacity: 1;
    }
    100% {
      height: 3.5em;
      width: 1.75em;
      opacity: 1;
    }
  }
  /*  */
  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fbf0f0;
    width: 60%;
    margin: 1rem auto;
    letter-spacing: 1px;
  }
  button,
  a,
  input {
    width: 260px;
    height: 50px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #fbf0f0;

    outline: none;
    border: none;
    cursor: pointer;
    margin: 1rem auto 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  input {
    margin-top: 0;
    background: rgba(80, 80, 80, 0.4);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid #212828;
    color: #fff;
    &::placeholder {
      color: #fff;
    }
  }
  .mint_button {
    background: rgba(80, 80, 80, 0.4);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid #212828;
    color: #fff;
    letter-spacing: 1px;
  }
  .connect {
    background: #5b454d;
  }
  .discord {
    background: #8830df;
  }
  .twitter {
    background: #5ac8fa;
  }
  form {
    margin-bottom: 1rem;
    p {
      color: #fff;
      text-align: center;
      width: 90% !important;
      margin: 0 auto;
      line-height: 140%;
      font-size: 0.8rem;
      word-wrap: wrap;
      word-break: break-all;
    }
    label {
      color: #fff;
      text-align: center;
      width: 90% !important;
      margin: 0 auto 1rem;
      padding-bottom: 1rem;
      line-height: 140%;
      font-size: 1rem;
    }
  }
  .email {
    margin-top: 1rem;
  }
  @media screen and (max-width: 1430px) {
    width: 90vw;
    min-height: 20vw;
    margin-bottom: 2rem;
    button,
    a {
      width: 90%;
      height: 50px;
    }
  }
`;
