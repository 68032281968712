import React from "react";
import { Container, DescContainer } from "./HeroStyles";
import mechDesktop from "../../images/burn_tower_BW.png";
import Fade from "react-reveal/Fade";
import Mint from "../mint/Mint";
import "../../index.css";

const Hero = () => {
  return (
    <>
      <Container name="hero">
        <Fade duration={6000}>
          <img src={mechDesktop} className="hero" alt="hero" />
        </Fade>
      <DescContainer>
      <h1>MECHARCADE</h1>
      <Mint />
      </DescContainer>
      </Container>
    </>
  );
};

export default Hero;
