import styled from "styled-components";
import { CgMenuRight, CgClose } from "react-icons/cg";

export const Bars = styled(CgMenuRight)`
  height: 45px;
  width: auto;
  color: #f5f5f5;
  cursor: pointer;
  margin-left: 1.5rem;
  @media screen and (min-width: 1000px) {
    display: none;
  }
`;
export const BarsClose = styled(CgClose)`
  height: 45px;
  width: auto;
  color: #f5f5f5;
  cursor: pointer;
  margin-left: 1.5rem;
  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

export const Navigation = styled.nav`
  width: 100%;
  height: 100px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-box-shadow: 0px 0px 26px 4px #000000;
  box-shadow: 0px 0px 26px 10px #000000;
  @media screen and (max-width: 1000px) {
    height: 80px;
  }
`;

export const NavigationContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    cursor: pointer;
  }
  @media screen and (max-width: 1400px) {
    width: 80%;
  }
`;

export const FlexLinks = styled.div`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 1.25rem;
    letter-spacing: 2px;
    margin-left: 2rem;
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const LogoImage = styled.img`
  height: 60px;
  width: auto;
  @media screen and (max-width: 1000px) {
  }
`;

export const Logo = styled.img`
  height: 55px;
  @media screen and (max-width: 1000px) {
    height: 40px;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a {
    cursor: pointer;
  }
  .wallet {
    display: none;
  }
  @media screen and (min-width: 501px) and (max-width: 1000px) {
    width: 40%;
    .icons_desktop {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    width: 50%;
    .icons_desktop {
      display: none;
    }
  }
`;

export const Icon = styled.a`
  text-decoration: none;
  margin: 0 0 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border: 1px solid #f5f5f5;
  transition: 0.3s;

  &:hover,
  &:focus {
    box-shadow: inset calc(45px) 0 0 0 #f5f5f5;
    svg {
      color: #000;
    }
  }
  svg {
    font-size: 1.15rem;
    color: var(--white);
    cursor: pointer;
  }
  @media screen and (max-width: 500px) {
    margin: 0 0 0 0.5rem;
  }
`;

export const IconDropdown = styled.a`
  text-decoration: none;
  margin: 0 0 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border: 1px solid #f5f5f5;
  transition: 0.3s;

  &:hover,
  &:focus {
    box-shadow: inset calc(45px) 0 0 0 #f5f5f5;
    svg {
      color: #000;
    }
  }
  svg {
    font-size: 1.15rem;
    color: var(--white);
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) and (min-width: 500px) {
    width: 65px;
    height: 65px;
    margin: 0 0 0 0.5rem;
    svg {
      font-size: 1.7rem;
    }
  }
  @media screen and (max-width: 500px) {
    margin: 0 0 0 0.5rem;
  }
`;

export const IconDropdownButton = styled.button`
  text-decoration: none;
  margin: 0 0 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border: 1px solid #f5f5f5;
  transition: 0.3s;
  background: #000;
  &:hover,
  &:focus {
    box-shadow: inset calc(45px) 0 0 0 #f5f5f5;
    svg {
      color: #000;
    }
  }
  svg {
    font-size: 1.15rem;
    color: var(--white);
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) and (min-width: 500px) {
    width: 65px;
    height: 65px;
    margin: 0 0 0 0.5rem;
    svg {
      font-size: 1.7rem;
    }
  }
  @media screen and (max-width: 500px) {
    margin: 0 0 0 0.5rem;
  }
`;

export const ConnectButton = styled.button`
  color: #f5f5f5;
  font-family: "Titillium Web", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  width: 180px;
  height: 45px;
  background: #000;
  letter-spacing: 2px;
  cursor: pointer;
  transition: 0.3s;
  outline: 0;
  border: 1px solid var(--white);
  z-index: 1;
  width: 180px;
  margin-left: 2rem;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #f5f5f5;
    transition: all 0.3s;
  }
  &:hover,
  &:focus {
    color: #000;
    &:after {
      left: 0;
      width: 180px;
    }
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const DropdownContainer = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  min-height: calc(20vh - 80px);
  width: 100vw;
  background: #000;
  z-index: 200;
`;

export const DropdownMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 60px) repeat(1, 100px);
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f5f5f5;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }
`;

export const DropdownLink = styled.a``;

export const DropDownFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60vw;
  margin: 1rem auto;
`;
