import React, { useEffect, useState } from "react";
import {
  Icon,
  IconsContainer,
  LogoImage,
  // Logo,
  Navigation,
  NavigationContainer,
  FlexLinks,
  Bars,
  DropdownContainer,
  DropdownMenu,
  DropDownFlex,
  BarsClose,
  IconDropdown,
  ConnectButton,
  IconDropdownButton,
} from "./HeaderStyles";
import logo from "../../images/mecharcadesvg.svg";
import { FaTwitter, FaWallet, FaMediumM } from "react-icons/fa";
import Opensea from "./Opensea";
import { FaDiscord } from "react-icons/fa";
import { Link } from "react-scroll";
import { useHistory } from "react-router";
import axios from "axios";
import { Link as LinkTo } from "react-router-dom";

const Header = ({ backhome }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  //
  //
  // eslint-disable-next-line
  const [walletAddress, setWallet] = useState("");
  // eslint-disable-next-line
  const [status, setStatus] = useState("");

  var access_token = "";
  //
  //
  const verifyWallet = async (saleId) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "api/wallet?saleId=" + saleId,
      {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    );
  };
  const createWallet = async (wallet) => {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/wallet",
      '{ "walletAddress" : "' + wallet + '" }',
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    access_token = response.data.data;
    verifyWallet(process.env.REACT_APP_SALE_ID);
  };
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          // status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a
                target="_blank"
                href={`https://metamask.io/download.html`}
                rel="noreferrer"
              >
                You must install a virtual Ethereum wallet in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };
  // eslint-disable-next-line
  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    createWallet(walletResponse.address);
  };

  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          createWallet(accounts[0]);
          //  setStatus("Write a message in the text-field above.");
        } else {
          setWallet("");
          // setStatus("Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          <a
            target="_blank"
            href={`https://metamask.io/download.html`}
            rel="noreferrer"
          >
            You must install a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  };

  const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          createWallet(addressArray[0]);
          return {
            address: addressArray[0],
            //status: "👆🏽 Write a message in the text-field above.",
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to an ethereum wallet using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a
                target="_blank"
                href={`https://metamask.io/download.html`}
                rel="noreferrer"
              >
                You must install a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };
  // eslint-disable-next-line
  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    addWalletListener();
  }, []);
  //
  //
  // eslint-disable-next-line
  let history = useHistory();
  return (
    <>
      <Navigation>
        <NavigationContainer>
          {backhome ? (
            <LinkTo to="/">
              <LogoImage
                src={logo}
                alt="logo"
                onClick={() => setMenuOpen(false)}
              />
            </LinkTo>
          ) : (
            <Link to="hero" duration={500} offset={-100}>
              <LogoImage
                src={logo}
                alt="logo"
                onClick={() => setMenuOpen(false)}
              />
            </Link>
          )}
          <IconsContainer>
            {backhome ? null : (
              <FlexLinks>
                {/* <Link to="extinct" duration={500} offset={-90}>
                  Story
                </Link> */}
                {/* <Link to="mechs" duration={500} offset={-110}>
                  Mechs
                </Link> */}
                <Link to="vision" duration={500} offset={-110}>
                  Our Vision
                </Link>
                <Link to="team" duration={500} offset={-110}>
                  Team
                </Link>
                {/* <Link to="faq" duration={500} offset={-90}>
                  Faq
                </Link> */}
              </FlexLinks>
            )}
            <Icon
              href="https://opensea.io/collection/mecharcadeofficial"
              className="icons_desktop"
              target="_blank"
            >
              <Opensea />
            </Icon>
            <Icon
              href="https://twitter.com/MechArcade"
              className="icons_desktop"
              target="_blank"
            >
              <FaTwitter />
            </Icon>
            <Icon
              href="https://discord.gg/MechArcade"
              className="icons_desktop"
              target="_blank"
            >
              <FaDiscord />
            </Icon>
            <Icon
              href="https://medium.com/@mecharcadeofficial"
              className="icons_desktop"
              target="_blank"
            >
              <FaMediumM />
            </Icon>
            <Icon href="https://twitter.com" className="wallet icons_desktop">
              <FaWallet />
            </Icon>
            {menuOpen ? (
              <BarsClose onClick={toggleMenu} />
            ) : (
              <Bars onClick={toggleMenu} />
            )}

              <ConnectButton 
             className="connect"
             onClick={connectWalletPressed}
             disabled={walletAddress.length > 0 ? true : false}
             style={{
               cursor: walletAddress.length > 0 ? "not-allowed" : "pointer",
             }}
           >
             {" "}
             {walletAddress.length > 0
               ? String(walletAddress).substring(0, 6) +
                 "..." +
                 String(walletAddress).substring(38)
               : "Connect Wallet"}
              </ConnectButton>
          </IconsContainer>
        </NavigationContainer>
      </Navigation>
      <DropdownContainer
        style={{
          top: menuOpen ? "80px" : "-100%",
          transition: "all 500ms ease",
        }}
        onClick={toggleMenu}
      >
        <DropdownMenu>
          <Link to="extinct" duration={500} offset={-90} onClick={toggleMenu}>
            Story
          </Link>
          <Link to="vision" duration={500} offset={-90} onClick={toggleMenu}>
            Our Vision
          </Link>
          <Link to="team" duration={500} offset={-90} onClick={toggleMenu}>
            Team
          </Link>
          <DropDownFlex>
            <IconDropdown href="https://twitter.com/MechArcade" target="_blank">
              <FaTwitter />
            </IconDropdown>
            <IconDropdown href="https://discord.gg/MechArcade" target="_blank">
              <FaDiscord />
            </IconDropdown>
            <IconDropdown href="https://opensea.io/collection/mecharcadeofficial" target="_blank">
              <Opensea />
            </IconDropdown>
            {walletAddress ? (
              <IconDropdownButton 
              onClick={connectWalletPressed}
              disabled={walletAddress.length > 0 ? true : false}
              style={{
                cursor: walletAddress.length > 0 ? "not-allowed" : "pointer",
              }}
            >
              {" "}
              {walletAddress.length > 0
                ? String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                : "Connect Wallet"}
                <FaWallet />
              </IconDropdownButton>
            ) : (
              <IconDropdownButton onClick={connectWalletPressed}>
                <FaWallet />
              </IconDropdownButton>
            )}
          </DropDownFlex>
        </DropdownMenu>
      </DropdownContainer>
    </>
  );
};

export default Header;
