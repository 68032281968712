import styled from "styled-components";

export const SecondContentContainer = styled.article`
color: white;
background: linear-gradient(
  146deg,
  rgba(22, 20, 20, 0.8) 50%,
  rgba(49, 64, 97, 0.35) 65%,
  rgba(97, 109, 115, 0.2) 91%);
border: 2px solid #cdeaec;
-webkit-box-shadow: 0px 0px 10px 1px #9bd2d6;
box-shadow: 0px 0px 10px 0.5px #9bd2d6;
width: 70vw;
min-height: 6vh;
padding-bottom: 1px:
padding-top: 10px;
text-align: center;
margin: auto;
  input {
    border: 1px solid #fff;
    width: 250px;
    height: 50px;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #fbf0f0;
    outline: none;
    cursor: pointer;
    margin: 1rem auto 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-top: 1rem;
    background: rgba(80, 80, 80, 0.8);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    color: #fff;
    &::placeholder {
      color: #fff;
    }
  }
  @media screen and (max-width: 1430px) {
    width: 75vw;
    input {
      margin: 2.7vh auto 6vh auto;
      width: 200px;
      height: 50px;      
    }
    button {
      margin: 1vh auto .1vh auto;
      width: 200px;
      height: 50px;
    }
  }
`;
